import "./App.css";
import Hero from "./components/hero";
import PPTexts from "./components/privacy-policy-text";

import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          color: "#fff",
          // Some CSS
          fontSize: "1rem",
        },
      },
    },
  },
  palette: {
    primary: {
      light: "#757ce8",
      main: "rgba(255, 255, 255, 0.88)",
      dark: "#002884",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000",
    },
  },
});

const timeElapsed = Date.now();
const today = new Date(timeElapsed);

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Hero />
        <div className="App-content">
          <PPTexts
            company={"Artome Oy"}
            appName={"Artome Connect"}
            contactEmail={"contact(at)artome.fi"}
            startDate={today.toDateString()}
          />
        </div>
      </ThemeProvider>
    </div>
  );
}

export default App;
