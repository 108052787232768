import ResponsiveAppBar from "./header";

export default function Hero() {
  return (
    <>
      <ResponsiveAppBar />
      <div className="hero-image" />
    </>
  );
}
